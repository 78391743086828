<template>
    <v-card>
        <v-card-title>
            <v-text-field single-line clearable v-model="search" append-icon="mdi-magnify" :label="$t('common.search')"></v-text-field>
        </v-card-title>

        <v-data-table
            dense
            :items="data.deleteData"
            :headers="header"
            :expanded="expanded"
            fixed-header
            height="400px"
            :footer-props="{
                pageText: '{0} - {1} из {2}',
                itemsPerPageText: 'Показывать на странице',
                itemsPerPageAllText: 'все',
                showFirstLastPage: true,
                itemsPerPageOptions: [25, 50, 100, -1],
            }"
            :search="search"
            sort-by="mode date"
            @click:row="onClickRow"
        >
            <template v-slot:[`item.name`]="{ item }">
                <span class="text-caption">
                    <b>{{ item.name }}</b>
                </span>
            </template>

            <template v-slot:[`item.finish_addr`]="{ item }">
                <td class="tooltip-text">
                    <span>{{ item.finish_addr.substr(0, 24) }}</span>
                </td>
            </template>

            <template v-slot:[`item.courier_name`]="{ item }">
                <b>{{ item.courier_name.length > 20 ? item.courier_name.substr(0, 20) + '...' : item.courier_name }}</b>
            </template>

            <template v-slot:[`item.date`]="{ item }">
                <span>{{ item.mode.split(' ')[0] }} {{ timeToString(item.date) }} </span>
            </template>

            <template v-slot:[`item.date_ready`]="{ item }">
                {{ timeToString(item.date_ready) }}
            </template>

            <template v-slot:[`item.date_delivery`]="{ item }">
                {{ timeToString(item.date_delivery) }}
            </template>

            <template v-slot:[`item.date_take`]="{ item }">
                <span class="mr-2">{{ timeToString(item.date_take) }}</span>
                <v-chip v-if="item.date_take" class="px-1 mb-1" :color="timeColorHtml(item.date_take, item.date_ready)" x-small label text-color="white">
                    {{ timeError(item.date_take, item.date_ready) }}
                </v-chip>
            </template>

            <template v-slot:[`item.date_complete`]="{ item }">
                <span class="mr-2">{{ timeToString(item.date_complete) }}</span>
                <v-chip v-if="item.date_complete" class="px-1 mb-1" :color="timeColorHtml(item.date_complete, item.date_delivery)" x-small label text-color="white">
                    {{ timeError(item.date_complete, item.date_delivery) }}
                </v-chip>
            </template>
            <template v-slot:[`item.source`]="{ item }">
                <td>
                    <v-avatar size="24">
                        <img alt="source" :src="iconSource(item)" />
                    </v-avatar>
                </td>
            </template>
            <!-- добавление выпадающего меню для кнопок восстановления -->
            <template v-if="!userIsPoint" v-slot:[`expanded-item`]="{ headers }">
                <td :colspan="headers.length">
                    <v-row class="ma-4 d-flex justify-space-around">
                        <v-col class="d-flex justify-center" xs="12" sm="3" offset-md="1" offset-lg="3">
                            <v-btn :disabled="disBtn" @click="restoreOrdersOnNew()" color="error">восстановить как новый</v-btn>
                        </v-col>
                        <v-col class="d-flex justify-center justify-sm-start">
                            <v-btn :disabled="disBtn" @click="restoreOrdersOnExist()" color="error">восстановить как был</v-btn>
                        </v-col>
                    </v-row>
                </td>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'

    moment.locale('ru')

    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'

    export default {
        props: {
            data: {
                deleteData: {
                    type: Array,
                    default() {
                        return {}
                    },
                },
                orderData: {
                    type: Object,
                    default() {
                        return {}
                    },
                },
            },
        },
        mixins: [Status, Orders],
        data() {
            return {
                //отображает выпадающее меню в удаленных заказах
                expanded: [],
                search: '',
                disBtn: false,
            }
        },
        created() {
            this.userIsPoint = this.$store.state.user.role != config.user.role.operator
        },

        computed: {
            header() {
                return [
                    { text: 'Сист.', value: 'source', sortable: false },
                    { text: 'Название.', value: 'name', sortable: false },
                    { text: 'Гость', value: 'guest', sortable: false },
                    { text: 'Адрес доставки', value: 'finish_addr', sortable: false },
                    { text: 'Курьер', value: 'courier_name', sortable: false },
                    { text: 'Готов', value: 'date', sortable: false },
                    { text: 'Забрать', value: 'date_ready', sortable: false },
                    { text: 'Доставить', value: 'date_delivery', sortable: false },
                    { text: 'Забран', value: 'date_take', sortable: false },
                    { text: 'Доставлен', value: 'date_complete', sortable: false },
                ]
            },
            //возвращает выбранный удаленный заказ
            selectRestoreOrder() {
                const result = this.expanded[0]
                return result
            },
        },
        methods: {
            //активирует выпадающее меню для выбранного заказа с кнопками восстановления
            onClickRow(value) {
                this.disBtn = false
                this.expanded = value === this.expanded[0] ? [] : [value]

                //запрет на восстановление
                const order = this.selectRestoreOrder
                if (
                    order.guest === null ||
                    order.guest.length <= 0 ||
                    order.finish_addr === null ||
                    order.finish_addr.length <= 0 ||
                    order.date === null ||
                    order.date.length <= 0 ||
                    order.date_ready === null ||
                    order.date_ready.length <= 0 ||
                    order.date_delivery === null ||
                    order.date_delivery.length <= 0
                ) {
                    this.disBtn = true
                    return
                }
                // console.log('test expanded', this.expanded[0]);
            },

            //кнопка восстановить как был
            restoreOrdersOnExist() {
                const order = this.selectRestoreOrder
                let status

                //в зависимости от параметров courier_tid, date_accept, date_take, date_complete, выставляем ему статус для восстановления
                if (order.courier_tid === null && order.date_accept === null && order.date_take === null && order.date_complete === null) {
                    status = 0
                }

                if (order.courier_tid !== null && order.date_accept === null && order.date_take === null && order.date_complete === null) {
                    status = 5
                }

                if (order.courier_tid !== null && order.date_accept !== null && order.date_take === null && order.date_complete === null) {
                    status = 1
                }

                if (order.courier_tid !== null && order.date_accept !== null && order.date_take !== null && order.date_complete === null) {
                    status = 2
                }
                //date_accept может быть пустым в статусе 3, потому что на сайте есть возможность сделать заказ доставленным (статус 3) в этом случае только date_take и date_compleate заполняются автоматически, а date_accept если был null так им и останется
                if (order.courier_tid !== null && order.date_take !== null && order.date_complete !== null) {
                    status = 3
                }

                const data = {
                    orderId: this.selectRestoreOrder.id,
                    status,
                    notify: true,
                    user: this.$store.state.user.name,
                    role: this.$store.state.user.role,
                }
                this.$store
                    .dispatch('restore_order', data)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order restore ok')
                            const data = {
                                status: true,
                                name: this.selectRestoreOrder.name,
                            }

                            //если ок, передаем родителю (Orders) инфу что бы закрыл попап и обновил данные по заказам
                            return this.$emit('successRestore', data)
                        }
                    })
                    .catch(err => {
                        console.log('order restore error', err.name, err.message)
                        const data = {
                            status: false,
                            name: this.selectRestoreOrder.name,
                        }
                        this.$emit('successRestore', data)
                    })
            },

            //кнопка восстановить как новый
            restoreOrdersOnNew() {
                const data = {
                    orderId: this.selectRestoreOrder.id,
                    status: 0,
                    notify: true,
                    user: this.$store.state.user.name,
                    role: this.$store.state.user.role,
                }

                this.$store
                    .dispatch('restore_order', data)
                    .then(res => {
                        if (res.data.success) {
                            // console.log("order restore ok");
                            const data = {
                                status: true,
                                name: this.selectRestoreOrder.name,
                            }
                            return this.$emit('successRestore', data)
                        }
                    })
                    .catch(err => {
                        console.log('order restore error', err.name, err.message)
                        const data = {
                            status: false,
                            id: this.selectRestoreOrder.id,
                        }
                        this.$emit('successRestore', data)
                    })
            },
        },
    }
</script>

<style lang="stylus">
    .v-data-table {
      padding: 0 16px;

      .v-data-table__wrapper > table > thead > tr {
        th {
          white-space: nowrap;
          padding: 0 8px;
        }
      }

      .v-data-table__wrapper > table > tbody > tr {
        cursor: pointer;

        td {
          white-space: nowrap;
          padding: 0 8px;
          font-size: 0.75 rem;

          p {
            margin-top: 6px;
            margin-bottom: 6px;
            height: 22px;
          }

          td {
            padding-left: 0;
          }
        }
      }
    }
</style>
