var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"single-line":"","clearable":"","append-icon":"mdi-magnify","label":_vm.$t('common.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"dense":"","items":_vm.data.deleteData,"headers":_vm.header,"expanded":_vm.expanded,"fixed-header":"","height":"400px","footer-props":{
            pageText: '{0} - {1} из {2}',
            itemsPerPageText: 'Показывать на странице',
            itemsPerPageAllText: 'все',
            showFirstLastPage: true,
            itemsPerPageOptions: [25, 50, 100, -1],
        },"search":_vm.search,"sort-by":"mode date"},on:{"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-caption"},[_c('b',[_vm._v(_vm._s(item.name))])])]}},{key:"item.finish_addr",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"tooltip-text"},[_c('span',[_vm._v(_vm._s(item.finish_addr.substr(0, 24)))])])]}},{key:"item.courier_name",fn:function(ref){
        var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.courier_name.length > 20 ? item.courier_name.substr(0, 20) + '...' : item.courier_name))])]}},{key:"item.date",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.mode.split(' ')[0])+" "+_vm._s(_vm.timeToString(item.date))+" ")])]}},{key:"item.date_ready",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeToString(item.date_ready))+" ")]}},{key:"item.date_delivery",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeToString(item.date_delivery))+" ")]}},{key:"item.date_take",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.timeToString(item.date_take)))]),(item.date_take)?_c('v-chip',{staticClass:"px-1 mb-1",attrs:{"color":_vm.timeColorHtml(item.date_take, item.date_ready),"x-small":"","label":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.timeError(item.date_take, item.date_ready))+" ")]):_vm._e()]}},{key:"item.date_complete",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.timeToString(item.date_complete)))]),(item.date_complete)?_c('v-chip',{staticClass:"px-1 mb-1",attrs:{"color":_vm.timeColorHtml(item.date_complete, item.date_delivery),"x-small":"","label":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.timeError(item.date_complete, item.date_delivery))+" ")]):_vm._e()]}},{key:"item.source",fn:function(ref){
        var item = ref.item;
return [_c('td',[_c('v-avatar',{attrs:{"size":"24"}},[_c('img',{attrs:{"alt":"source","src":_vm.iconSource(item)}})])],1)]}},(!_vm.userIsPoint)?{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"ma-4 d-flex justify-space-around"},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"xs":"12","sm":"3","offset-md":"1","offset-lg":"3"}},[_c('v-btn',{attrs:{"disabled":_vm.disBtn,"color":"error"},on:{"click":function($event){return _vm.restoreOrdersOnNew()}}},[_vm._v("восстановить как новый")])],1),_c('v-col',{staticClass:"d-flex justify-center justify-sm-start"},[_c('v-btn',{attrs:{"disabled":_vm.disBtn,"color":"error"},on:{"click":function($event){return _vm.restoreOrdersOnExist()}}},[_vm._v("восстановить как был")])],1)],1)],1)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }