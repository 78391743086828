<template>
    <div class="orders">
        <!-- snackbar случайно удаленных заказов -->
        <template>
            <v-container class="pa-0">
                <v-snackbar right bottom :value="snackbarText" :timeout="6000" v-model="snackbar" color="info">
                    <v-progress-circular color="deep-orange lighten-2" :value="Math.floor(100 * (currentTime / snackbarTime))" class="mx-2">
                        <strong>{{ snackbarLoad }}</strong>
                    </v-progress-circular>

                    <span class="mx-2 bold">
                        <b>{{ snackbarText }}</b>
                    </span>

                    <v-btn color="deep-orange " @click="snackRestoreOrder"> <span color="black">Восстановить</span> </v-btn>
                </v-snackbar>
            </v-container>
        </template>
        <v-row style="max-width: 1800px" class="mx-auto">
            <v-col cols="12">
                <v-card class="mx-auto elevation-6">
                    <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            <!-- {{ $t('orders.list_of_orders', { p1: orders.length, p2: ordersComplete, p3: ordersFee }) }} -->
                            Всего заказов: {{ orders.length }}, доставлено: {{ ordersComplete }}, {{ userIsPoint ? 'расходы' : 'выручка' }}: {{ ordersFeePoint }} ₽{{ userIsPoint ? '' : ', зарплата: ' + ordersFeeCourier + ' ₽' }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn-toggle v-model="calendar" tile group mandatory dense>
                            <v-btn value="left" @click="onClickToday"> <v-icon>mdi-calendar</v-icon> {{ $t('orders.buttons.today') }} </v-btn>
                            <v-btn value="center" @click="onClickYesterday"> <v-icon>mdi-calendar-arrow-left</v-icon> {{ $t('orders.buttons.yesterday') }} </v-btn>
                            <v-btn value="right" @click="onClickMonth"> <v-icon>mdi-calendar-month</v-icon> {{ $t('orders.buttons.month') }} </v-btn>
                            <v-menu v-model="menu_calendar" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-btn text class="menu-button" v-on="on">
                                        <v-icon class="mr-1">mdi-calendar-multiple</v-icon>
                                        {{ $t('orders.buttons.range') }}
                                    </v-btn>
                                </template>
                                <div @keydown.esc="closeCalendar" tabindex="0">
                                    <v-date-picker range v-model="dates" locale="ru-ru" selected-items-text="Выбран период" :first-day-of-week="1" @change="onClickChangeDate"> </v-date-picker>
                                </div>
                            </v-menu>
                        </v-btn-toggle>

                        <v-spacer></v-spacer>

                        <!-- Удаленные заказы (можно найти через поиск "Удаленные заказы") -->

                        <!-- вызывается попап если корзина активна -->
                        <v-dialog scrollable v-model="popupDeletedOrder">
                            <template v-slot:activator="{ on, attrs }">
                                <!-- корзина вызывающая попап -->
                                <v-btn v-bind="attrs" v-on="on" :disabled="deletedOrdersCount === 0" icon>
                                    <!-- v-badge :content - показывает значения :value - отображает значок-->
                                    <v-badge overlap :content="deletedOrdersCount" :value="deletedOrdersCount" color="red">
                                        <v-icon large>mdi-trash-can-outline</v-icon>
                                    </v-badge>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="d-flex justify-space-between text-h5 grey lighten-2">
                                    Удаленные заказы
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" color="primary" text @click="popupDeletedOrder = false">Закрыть</v-btn>
                                    </v-card-actions>
                                </v-card-title>
                                <template>
                                    <v-card>
                                        <tableDeleted :data="{ deleteData: deletedOrdersData, orderData: orders }" @successRestore="restoreStatus"></tableDeleted>
                                    </v-card>
                                </template>
                            </v-card>
                        </v-dialog>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span class="tooltip-text" v-bind="attrs" v-on="on">
                                    <v-btn @click="onClickDownloadData" class="mx-4 px-2" tile icon rounded>
                                        <v-img src="../../assets/images/excel-300.png" width="40"></v-img>
                                    </v-btn>
                                </span>
                            </template>
                            <span>Выгрузка данных в таблицу</span>
                        </v-tooltip>

                        <v-btn icon @click="onClickUpdate">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-title class="pt-0 pb-2 d-flex">
                        <v-select v-if="!userIsPoint" class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" @change="updateData" :items="filter_points" name="point" item-text="name" item-value="id" v-model="filter_point" label="По точке" hide-details></v-select>
                        <v-select class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" @change="updateData" :items="filter_couriers" name="courier" item-text="name" item-value="tid" v-model="filter_courier" label="По курьеру" hide-details></v-select>
                        <v-select class="mx-2 flex-grow-0" style="width: 220px" no-data-text="Нет данных" @change="updateData" :items="filter_order_modes" name="order_mode" v-model="filter_order_mode" label="По типу" hide-details></v-select>
                        <v-btn v-if="!userIsPoint" outlined small tile color="primary" class="mx-2 mt-3 flex-grow-0" style="height: 35px" @click="clickFeeUpdate()">Обновить цены<br />на доставку</v-btn>

                        <v-spacer></v-spacer>

                        <v-text-field style="width: 340px; flex: initial" v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
                    </v-card-title>

                    <v-data-table
                        :mobile-breakpoint="0"
                        dense
                        no-data-text="Нет данных"
                        no-results-text="Нет данных, попробуйте изменить запрос"
                        :footer-props="{
                            pageText: '{0} - {1} из {2}',
                            itemsPerPageText: 'Показывать на странице',
                            itemsPerPageAllText: 'все',
                            showFirstLastPage: true,
                            itemsPerPageOptions: [25, 50, 100, -1],
                        }"
                        :headers="headers"
                        :search="search"
                        :items="orders"
                        item-key="index"
                        :loading="loading"
                        :loading-text="$t('common.loading')"
                        @click:row="onClickOrderEdit($event)"
                    >
                        <template v-slot:[`item.name`]="{ item }">
                            <b>{{ item.name }}</b>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                            <div class="d-flex">
                                <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <!-- <span v-bind="attrs" v-on="on" class="item-order d-inline-block mx-3" :style="orderStatusStyle(order)">{{ order.id }}</span> -->
                                        <v-chip v-bind="attrs" v-on="on" :color="orderStatusColorHtml(item.status)" text-color="white">
                                            <v-icon size="small" class="mr-1">{{ orderStatusIcon(item.status) }}</v-icon>
                                            {{ orderStatusStr(item.status) }}
                                        </v-chip>
                                    </template>

                                    <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                        <div class="order-card" v-html="orderContent(item)"></div>
                                    </v-card>
                                </v-menu>
                                <v-btn class="mt-1" v-if="!userIsPoint && item.status != config.order.status.complete && (item.status != config.order.status.new || item.courier_tid)" outlined text tile x-small @click="onClickOrderStatus(item)"><v-icon small>mdi-pencil-outline</v-icon></v-btn>
                            </div>
                        </template>

                        <template v-slot:[`item.guest`]="{ item }">
                            <span style="min-width: 180px">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <td v-bind="attrs" v-on="on">
                                            <router-link v-if="item.guest_phone" :to="{ name: 'guests_phone', params: { phone: item.guest_phone } }" class="text-decoration-none" style="color: inherit">
                                                <span class="py-2 tooltip-text" :class="loading || item.status == config.order.status.forming ? '' : item.guest_phone ? 'guest-selectable' : 'guest-error'" @click="onClickGuest(item)">{{ getGuestPhoneName(item) }}</span>
                                            </router-link>

                                            <span v-else class="py-2 tooltip-text">{{ getGuestStr(item.guest) }}</span>
                                        </td>
                                    </template>
                                    <span>{{ getGuestStr(item.guest) }}</span>
                                </v-tooltip>
                                <v-btn class="ml-2" v-if="!item.guest_id && item.status != config.order.status.forming" outlined tile text x-small @click="onClickGuestCreate(item)"><v-icon small>mdi-account-plus-outline</v-icon></v-btn>
                            </span>
                        </template>

                        <template v-slot:[`item.finish_addr`]="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <td class="tooltip-text" v-bind="attrs" v-on="on">
                                        <span :style="item.fee_courier == 0 || item.fee_courier == $store.state.settings.price_courier_default ? 'color: red' : ''">{{ item.finish_addr.substr(0, 24) }}</span>
                                    </td>
                                </template>
                                <span>{{ item.finish_addr }}</span>
                            </v-tooltip>
                        </template>

                        <template v-slot:[`item.fee_courier`]="{ item }">
                            <span :style="item.fee_courier == 0 || item.fee_courier == $store.state.settings.price_courier_default ? 'color: red' : ''">{{ +item.fee_courier }}</span>
                        </template>

                        <template v-slot:[`item.fee_point`]="{ item }">
                            <span :style="item.fee_point == 0 || item.fee_point == $store.state.settings.price_point_default ? 'color: red' : ''">{{ +item.fee_point }}</span>
                        </template>

                        <template v-slot:[`item.options`]="{ item }">
                            <span>{{ distance(item.options) }}</span>
                        </template>

                        <template v-slot:[`item.courier_name`]="{ item }">
                            <v-avatar size="24" class="mr-2" v-if="item.courier_name != '--'">
                                <img alt="courier" :src="`${config.api.delivery + 'user_avatar?tid=' + item.courier_tid}`" />
                            </v-avatar>
                            <b>{{ item.courier_name.length > 20 ? item.courier_name.substr(0, 20) + '...' : item.courier_name }}</b>
                        </template>

                        <template v-slot:[`item.date`]="{ item }"> {{ item.mode.split(' ')[0] }} {{ timeToString(item.date) }}</template>

                        <template v-slot:[`item.date_ready`]="{ item }">
                            {{ timeToString(item.date_ready) }}
                        </template>

                        <template v-slot:[`item.date_delivery`]="{ item }">
                            {{ timeToString(item.date_delivery) }}
                        </template>

                        <template v-slot:[`item.date_take`]="{ item }">
                            <span class="mr-2">{{ timeToString(item.date_take) }}</span>
                            <v-chip v-if="item.date_take" class="px-1 mb-1" :color="timeColorHtml(item.date_take, item.date_ready)" x-small label text-color="white">
                                {{ timeError(item.date_take, item.date_ready) }}
                            </v-chip>
                        </template>

                        <template v-slot:[`item.date_complete`]="{ item }">
                            <span class="mr-2">{{ timeToString(item.date_complete) }}</span>
                            <v-chip v-if="item.date_complete" class="px-1 mb-1" :color="timeColorHtml(item.date_complete, item.date_delivery)" x-small label text-color="white">
                                {{ timeError(item.date_complete, item.date_delivery) }}
                            </v-chip>
                        </template>

                        <template v-slot:[`item.source`]="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <td class="tooltip-text" v-bind="attrs" v-on="on">
                                        <v-avatar size="24">
                                            <img alt="source" :src="iconSource(item)" />
                                        </v-avatar>
                                    </td>
                                </template>
                                <span>{{ tooltipSource(item) }}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogEdit" width="720">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    {{ dialogHeader }}
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field :disabled="userIsPoint" v-model="selectedItem.guest" label="Гость" hide-details></v-text-field>
                                </v-col>

                                <v-col cols="8">
                                    <v-text-field :disabled="userIsPoint" v-model="selectedItem.finish_addr" label="Адрес доставки" @input="onChangeAddress" hide-details></v-text-field>
                                </v-col>

                                <v-col cols="4" class="d-flex">
                                    <v-btn v-if="!userIsPoint" :disabled="priceUpdateFlag" :loading="priceUpdateFlag" elevation="2" class="float-right ma-0 pa-0 mr-2 mt-5" icon small @click="onClickPriceUpdate" hide-details>
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-text-field v-if="!userIsPoint" class="mx-1" :disabled="userIsPoint" v-model="selectedItem.fee_courier" label="Цена курьера" hide-details></v-text-field>
                                    <v-text-field class="mx-1" :disabled="userIsPoint" v-model="selectedItem.fee_point" label="Цена точки" hide-details></v-text-field>
                                </v-col>
                                
                                <v-col cols="12">
                                    <v-text-field :disabled="userIsPoint" v-model="selectedItem.pay_cost" label="Цена заказа" hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field :disabled="userIsPoint" v-model="selectedItem.descr" label="Комментарий" hide-details></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-select :disabled="userIsPoint" class="flex-grow-0" style="width: 220px" no-data-text="Нет данных" :items="couriers_list" name="courier" item-text="name" item-value="tid" v-model="selectedItem.courier_tid" label="Доставка курьером" hide-details></v-select>
                                </v-col>

                                <v-col cols="12" v-if="!userIsPoint && selectedItem.point_tid">
                                    <span
                                        >Менеджер: <b>{{ selectedItem.point_tid }}</b></span
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!userIsPoint" :disabled="currentItem.status === config.order.status.forming" class="mx-2" color="success" dark @click="onSaveDialogEdit">Сохранить</v-btn>
                    <v-btn v-if="!userIsPoint" :disabled="disBtn" class="mx-2" color="error" text @click="onDeleteBtnDialogEdit">Удалить</v-btn>
                    <v-btn class="mx-2" color="primary" text @click="dialogEdit = false">Закрыть</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogStatus" width="unset">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    {{ dialogHeader }}
                </v-card-title>

                <v-card-text>
                    <div class="d-flex px-4 py-5 justify-md-space-around">
                        <v-btn class="ma-2" v-if="currentItem.status == config.order.status.way_guest || currentItem.status == config.order.status.way_point || currentItem.status == config.order.status.passed" :color="orderStatusColorHtml(config.order.status.new)" dark @click="onClickOrderNew()"
                            >Новый</v-btn
                        >

                        <v-btn
                            class="ma-2"
                            v-if="currentItem.status == config.order.status.way_point || currentItem.status == config.order.status.way_guest || currentItem.status == config.order.status.passed || currentItem.courier_tid"
                            :color="orderStatusColorHtml(config.order.status.complete)"
                            dark
                            @click="onClickOrderComplete()"
                            >Доставлен</v-btn
                        >

                        <v-btn class="ma-2" v-if="currentItem.status == config.order.status.forming" color="error" dark @click="onClickOrderDelete()">Удалить</v-btn>
                    </div>
                    <v-divider></v-divider>
                    <span v-html="dialogContent"></span>
                </v-card-text>
            </v-card>
        </v-dialog>

        <span class="pb-16 d-block" />

        <v-dialog v-model="promptFeeUpdate" width="500">
            <v-card>
                <v-card-title class="headline grey lighten-2"> Подтверждение действия </v-card-title>

                <v-card-text class="py-4">
                    <span>Вы уверены, что хотите <b>обновить цены на доставку для всех выбранных заказов</b>?</span>
                    <br />
                    <span class="red--text">Внимание: результат нельзя будет вернуть назад!</span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" color="success" dark @click="promptFeeUpdateYes">Обновить</v-btn>
                    <v-btn class="mx-2" color="error" text @click="promptFeeUpdate = false">Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'

    moment.locale('ru')

    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'
    import Orders from '../../mixins/Orders.js'
    //подключаем таблицу "Удаленные заказы"
    import tableDeleted from './tableDeleted.vue'

    export default {
        components: {
            tableDeleted,
        },
        mixins: [DateTime, Status, Orders],
        data() {
            return {
                // *** Удаленные заказы ***
                //snackbar случайно удаленных заказов
                snackbar: false,
                snackbarText: '',
                snackbarTime: 5000,
                currentTime: 0,
                //отключение кнопки удаления (был баг что в если во время snackbar открыть другой заказ и пытаться непрерывно кликать по кнопке удалить (которая в момент удаления disabled), то в момент когда пропадает snackbar и кнопка активируется можно успеть передать параметр закликанного заказа в currentDeleteItem и досрочно отправить этот заказ в нотифай предыдущего удаления. По факту удалился предыдущий заказ а в нотифай полетел закликанный.
                disBtn: false,
                //сбрасывает interval
                snackbarCancelInterval: false,
                //фиксирует удаленный заказ для snackbar (был баг что в процессе удаления, когда висит snackbar, при нажатии на другие заказы он ловил другие заказы и отправлял их в нотифай как удаленные)
                currentDeleteItem: {},

                // переключатель попап удаленных заказов
                popupDeletedOrder: false,
                // данные по удаленным заказам
                deletedOrdersData: [],
                // *** Удаленные заказы конец ***

                dates: [],
                calendar: 0,
                menu_calendar: false,
                loading: false,
                search: '',
                searchDeleted: '',
                ordersComplete: '--',
                ordersFeePoint: '--',
                ordersFeeCourier: '--',
                orders: [],
                points: [],
                couriers: [],
                filter_point: 0,
                filter_points: [],
                filter_courier: 0,
                filter_couriers: [],
                couriers_list: [],
                filter_order_mode: 0,
                filter_order_modes: [
                    {
                        text: '< Все >',
                        value: 0,
                    },
                    {
                        text: '⏰ Предзаказ',
                        value: 1,
                    },
                    {
                        text: '🔥 Ближайший',
                        value: 2,
                    },
                ],
                headers: [],
                orderStatuses: [],
                timer: null,
                dialogStatus: false,
                dialogEdit: false,
                dialogHeader: '',
                dialogContent: '',
                config,
                currentItem: {},
                selectedItem: {},
                timerPriceUpdate: null,
                priceUpdateFlag: false,
                changeFlag: false,
                d1: null,
                d2: null,
                userIsPoint: false,
                guestNameClick: false,
                guestCreateClick: false,
                promptFeeUpdate: false,
            }
        },
        created() {
            this.userIsPoint = this.$store.state.user.role != config.user.role.operator
            if (!this.userIsPoint) {
                this.headers = [
                    {
                        text: 'Сист.',
                        value: 'source',
                        sortable: false,
                    },
                    {
                        text: 'Название',
                        value: 'name',
                    },
                    {
                        text: 'Статус',
                        value: 'status',
                    },
                    {
                        text: 'Гость',
                        sortable: false,
                        value: 'guest',
                    },
                    {
                        text: 'Адрес доставки',
                        sortable: false,
                        value: 'finish_addr',
                    },
                    {
                        text: 'Цена курьера',
                        value: 'fee_courier',
                    },
                    {
                        text: 'Цена точки',
                        value: 'fee_point',
                    },
                    {
                        text: 'Расстояние',
                        value: 'options',
                    },
                    {
                        text: 'Курьер',
                        value: 'courier_name',
                    },
                    {
                        text: 'Готов',
                        value: 'date',
                    },
                    {
                        text: 'Забрать',
                        value: 'date_ready',
                    },
                    {
                        text: 'Доставить',
                        value: 'date_delivery',
                    },
                    {
                        text: 'Забран',
                        value: 'date_take',
                    },
                    {
                        text: 'Доставлен',
                        value: 'date_complete',
                    },
                ]
            } else {
                this.headers = [
                    {
                        text: 'Сист.',
                        value: 'source',
                        sortable: false,
                    },
                    {
                        text: 'Название',
                        value: 'name',
                    },
                    {
                        text: 'Статус',
                        value: 'status',
                    },
                    {
                        text: 'Гость',
                        sortable: false,
                        value: 'guest',
                    },
                    {
                        text: 'Адрес доставки',
                        sortable: false,
                        value: 'finish_addr',
                    },
                    {
                        text: 'Цена',
                        value: 'fee_point',
                    },
                    {
                        text: 'Расстояние',
                        value: 'options',
                    },
                    {
                        text: 'Курьер',
                        value: 'courier_name',
                    },
                    {
                        text: 'Готов',
                        value: 'date',
                    },
                    {
                        text: 'Забрать',
                        value: 'date_ready',
                    },
                    {
                        text: 'Доставить',
                        value: 'date_delivery',
                    },
                    {
                        text: 'Забран',
                        value: 'date_take',
                    },
                    {
                        text: 'Доставлен',
                        value: 'date_complete',
                    },
                ]
            }
        },
        mounted() {
            console.log('orders start!')
            this.dates[0] = moment().format('YYYY-MM-DD')
            this.dates[1] = moment().format('YYYY-MM-DD')
            this.d1 = this.getDate1String()
            this.d2 = this.getDate2String()
            this.updateData()
        },

        beforeDestroy() {},

        destroyed() {
            return this.timer ? clearInterval(this.timer) : false
        },
        watch: {},
        computed: {
            dateRangeText() {
                return this.dates.join(' ~ ')
            },
            // *** Удаленные заказы ***
            // активирует/блокирует корзину и показывает число удаленных заказов на ней
            snackbarLoad() {
                let result = Math.floor(5 - (100 * (this.currentTime / this.snackbarTime)) / 20)
                if (result >= 0) {
                    return result
                }
                return 0
            },

            deletedOrdersCount() {
                return this.deletedOrdersData.length
            },
            selectRestoreOrder() {
                const result = this.expanded[0]
                return result
            },
            //*** Удаленные заказы конец */

        },
        methods: {
            // *** Удаленные заказы ***
            //сработает после окончания таймера
            notifyDeleteOrder() {
                const order = this.currentDeleteItem
                const user = this.$store.state.user.name
                const userRole = this.$store.state.user.role
                console.log('user name = ', user)
                console.log('user role = ', userRole)
                const data = {
                    order,
                    user,
                    userRole,
                }
                this.$store
                    .dispatch('order_delete_notify', data)
                    .then(res => {
                        if (res.data.success) {
                            console.log('notify ok')
                        }
                    })
                    .catch(err => {
                        console.log('notify false', err.name, err.message)
                    })
            },
            //возвращает удаленный заказ обратно в список
            snackRestoreOrder() {
                this.snackbar = false
                this.snackbarCancelInterval = true

                const restoreOrder = this.currentDeleteItem
                const indexOrder = this.currentDeleteItem.indexOrder
                delete this.currentDeleteItem.indexOrder
                return this.orders.splice(indexOrder, 0, restoreOrder)
            },
            //запускает таймер по восстановлению заказа. По окончании отправляет notify в телеграмм бот что заказ удален
            syncPbar() {
                console.log('start syncPbar')

                //останавливаем отправку notify если нажали кнопку восстановления
                if (this.snackbarCancelInterval) {
                    this.snackbarCancelInterval = false
                    return clearTimeout(timerId)
                }

                let timerId = setTimeout(() => {
                    //Increment the time counter by 100
                    this.currentTime += 1000
                    this.syncPbar()
                }, 1000)

                if (this.currentTime > this.snackbarTime) {
                    this.snackbar = false
                }
                if (this.currentTime > this.snackbarTime + 1200 && this.snackbarCancelInterval === false) {
                    clearInterval(timerId)
                    //Запускает удаление
                    this.deleteOrder()
                }
            },

            //получает все удаленные заказы за конкретную дату (так же активируется при срабатывании updateData() )
            getDeletedOrders() {
                // this.loading = true
                this.deletedOrdersData = []

                this.$store
                    .dispatch('orders_deleted', {
                        date1: this.d1,
                        date2: this.d2,
                        point_id: this.userIsPoint ? this.$store.state.user.point_id : this.filter_point,
                        deliveryTime: true,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.deletedOrdersData = res.data.orders.reverse()
                            //  this.loading = false
                            // console.log('test func revers deleted Orders', this.deletedOrdersData);
                            // console.log('test deletedOrdersLength', this.deletedOrdersData.length);
                        }
                    })
            },
            //получает данные от ребенка (tableDeleted), закрывая попап и обновляя данные если true
            restoreStatus(data) {
                console.log('test restore data = ', data)
                if (data.status) {
                    this.updateData()
                    this.popupDeletedOrder = false
                    return this.restoreSuccess(data.name)
                } else {
                    console.log('restore false')
                    return this.restoreError(data.name)
                }
            },
            restoreSuccess(name) {
                console.log('test restoreSuccess = ', name)
                const text = `Восстановление ${name} прошло успешно`
                this.$notify.toast(text, { color: 'info', x: 'right', y: 'bottom', timeout: 1500 })
            },
            restoreError(name) {
                const text = `Ошибка восстановления заказа ${name}`
                this.$notify.toast(text, { color: 'error', x: 'right', y: 'bottom', timeout: 1500 })
            },
            setTimer() {
                if (this.count > 0) {
                    setTimeout(() => {
                        this.count -= 1
                        console.log('test count', this.count)
                        this.setTimer()
                    }, 1000)
                }
            },

            // *** Удаленные заказы конец ***

            onClickGuest(order) {
                if (order.guest_id) {
                    this.guestNameClick = true
                    const name = order.guest_name ? order.guest_name + ' ' : ''
                    this.$notify.toast('Гость: ' + name + order.guest_phone, { x: 'right', y: 'top', color: 'info', timeout: 4000 })
                    setTimeout(() => {
                        this.guestNameClick = false
                    }, 100)
                }
            },
            onClickGuestCreate(order) {
                this.guestCreateClick = true
                this.$store.state.loading = true

                this.$store
                    .dispatch('guest_create', order)
                    .then(res => {
                        if (res.data.success) {
                            const guest = res.data.guest
                            order.guest_id = guest.id
                            order.guest_name = guest.name
                            order.guest_phone = guest.phone
                            this.$notify.toast('Успешно создан гость ' + guest.name, { color: 'success', timeout: 4000 })
                        } else {
                            this.$notify.toast('Не удалось создать гостя, нет телефона', { color: 'error', timeout: 4000 })
                        }
                        this.guestCreateClick = false
                        this.$store.state.loading = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.guestCreateClick = false
                        this.$store.state.loading = false
                        this.$notify.toast('Не удалось создать гостя, нет телефона', { color: 'error', timeout: 4000 })
                    })
            },
            getGuestPhoneName(order) {
                let guest_name = ''
                if (order.guest_id && order.guest_phone) {
                    guest_name += order.guest_phone
                    if (order.guest_name) guest_name += ' - ' + order.guest_name
                } else {
                    if (order.guest) {
                        guest_name = this.getGuestStr(order.guest).substr(0, 24)
                    }
                }
                return guest_name
            },
            priceUpdate() {
                // тут интересная ситуация - вызывается при смене адреса, то есть если у курьера ставка фиксированная, то координаты заказа не обновятся
                // ! нужно разнести обновление адреса-координат и прайса

                console.log('priceUpdate()')
                let courier = this.couriers.find(c => c.tid == this.selectedItem.courier_tid)
                // if (courier && courier.settings && courier.settings.tariff > 0) {
                // console.log('priceUpdate step 1')
                // this.selectedItem.fee_courier = courier.settings.tariff
                // this.priceUpdateFlag = false
                // } else {
                console.log('priceUpdate step 2')
                let point_id = this.selectedItem.point_id
                // let point_id = 0
                // if (!this.userIsPoint) {
                //     const point = this.points.find(point => point.id == this.selectedItem.point_id)

                //     if (point) {
                //         point_id = point.id
                //     }
                // } else {
                //     point_id = this.$store.state.user.point_id
                // }
                console.log('this.selectedItem before', this.selectedItem)
                this.$store
                    .dispatch('point_address', { point_id, address: this.selectedItem.finish_addr })
                    .then(res => {
                        if (res.data.success) {
                            console.log('point_address ok', res.data)
                        } else {
                            console.log('point_address fail')
                        }

                        this.selectedItem.fee_point = +res.data.price.point
                        this.priceUpdateFlag = false
                        if (courier && courier.settings && courier.settings.tariff > 0) {
                            this.selectedItem.fee_courier = +courier.settings.tariff
                        } else {
                            this.selectedItem.fee_courier = +res.data.price.courier
                        }

                        this.selectedItem.coordinates = JSON.stringify(res.data.coordinates)
                        this.selectedItem.options = JSON.parse(this.selectedItem.options)
                        this.selectedItem.options.distance = res.data.distance
                        this.selectedItem.options = JSON.stringify(this.selectedItem.options)
                        this.changeFlag = true
                        console.log('this.selectedItem after', this.selectedItem)
                    })
                    .catch(err => {
                        console.log(err)
                        this.priceUpdateFlag = false
                    })
                // }
            },
            onClickPriceUpdate() {
                if (this.timerPriceUpdate) {
                    clearTimeout(this.timerPriceUpdate)
                    this.timerPriceUpdate = null
                }
                this.priceUpdateFlag = true
                this.selectedItem.fee_courier = 'Поиск...'
                this.selectedItem.fee_point = 'Поиск...'
                this.priceUpdate()
            },
            onChangeAddress() {
                // тут так должно быть!
                this.priceUpdateFlag = true
                this.selectedItem.fee_courier = '...'
                this.selectedItem.fee_point = '...'
                if (this.timerPriceUpdate) {
                    clearTimeout(this.timerPriceUpdate)
                    this.timerPriceUpdate = null
                }
                this.timerPriceUpdate = setTimeout(() => {
                    this.timerPriceUpdate = null
                    this.priceUpdateFlag = true
                    this.selectedItem.fee_courier = 'Поиск...'
                    this.selectedItem.fee_point = 'Поиск...'
                    this.priceUpdate()
                }, 2000)
            },
            onDeleteBtnDialogEdit() {
                // сохраняем выбранный заказ
                this.currentDeleteItem = this.currentItem
                this.dialogEdit = false
                this.snackbarStart()
            },
            //вызывает snackbar быстрой отмены удаления
            snackbarStart(index) {
                //отключение кнопок удаления на время snackbar
                this.disBtn = true
                const orderId = this.currentDeleteItem.id
                // находим индекс заказа, что бы вставить его туда же откуда взяли
                const indexOrder = this.orders.findIndex(order => order.id === orderId)
                //добавляем в заказ на случай восстановления через snackbar
                this.currentDeleteItem.indexOrder = indexOrder
                //удаляем заказ из списка
                this.orders = this.orders.filter(order => order.id != orderId)
                const orderName = this.currentDeleteItem.name
                this.snackbar = true
                this.currentTime = 0
                this.snackbarText = `Заказ ${orderName}`

                this.syncPbar()
            },
            deleteOrder() {
                console.log('this.currentDeleteItem', this.currentDeleteItem)
                // const userRole = this.$store.state.user.role
                // const date = new Date().toISOString()
                const order = this.currentDeleteItem
                //удаляем индекс от snackbar
                delete order.indexOrder
                // order.date_delete = date
                // order.who_delete = userRole
                //передаем статус удаления
                order.status_delete = config.order.status.deleted

                this.$store
                    .dispatch('order_delete', order.id)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order delete ok')
                            //можно ли тут так оставить? или лучше сделать последовательно друг за другом?
                            this.notifyDeleteOrder()
                            this.getDeletedOrders()
                            this.disBtn = false
                        } else {
                            console.log('order delete fail')
                        }
                    })
                    .catch(err => {
                        console.log('order_delete error', err.name, err.message)
                        this.dialogEdit = false
                    })
            },
            onSaveDialogEdit() {
                // тут есть нюанс. была ситуация: заказ в статусе создается, открыли этот диалог, в боте поменяли статус заказа на новый, диалог закрыли -> статус заказа обновился с новый на создается и при этом заказ пропадает в боте в списке необработанных заказов, теперь сюда внесены изменения по проверке изменения полей и обновлению заказа в апи только при изменении этих полей. Но это не решает проблему до конца, так как если поля поменяют, то произойдет так же пропажа заказа в боте. решение - обновлять только поля guest, finish_addr и fee_courier, но для этого требуется в api поменять функцию updateOrder - принято еще другое решение: делается запрет на открытие диалога при заказе со статусом формируется

                // ! а еще произошла интересней проблема - заказ открыли для редактирования, у заказа status = 2, в этот момент курьер доставил заказ (сделал status = 3), в форме поменяли адрес и комментарий, нажали сохранить и перезаписали status на недоставленный, потом курьеру пришлось еще раз закрывать заказ. это привело к тому, что заказ стал сильно запоздалым и отправились 2 смс с отзывом

                // ! проверить как работает этот fix

                const orderSave = {
                    id: this.selectedItem.id,
                }
                
                if (this.changeFlag) {
                    this.currentItem.options = this.selectedItem.options
                    orderSave.options = this.selectedItem.options
                }

                if (this.currentItem.guest != this.selectedItem.guest) {
                    this.currentItem.guest = this.selectedItem.guest
                    orderSave.guest = this.selectedItem.guest
                    this.changeFlag = true
                }
                if (this.currentItem.finish_addr != this.selectedItem.finish_addr) {
                    this.currentItem.finish_addr = this.selectedItem.finish_addr
                    orderSave.finish_addr = this.selectedItem.finish_addr
                    this.changeFlag = true
                }
                if (this.currentItem.descr != this.selectedItem.descr) {
                    this.currentItem.descr = this.selectedItem.descr
                    orderSave.descr = this.selectedItem.descr
                    this.changeFlag = true
                }
                if (this.currentItem.coordinates != this.selectedItem.coordinates) {
                    this.currentItem.coordinates = this.selectedItem.coordinates
                    orderSave.coordinates = this.selectedItem.coordinates
                    this.changeFlag = true
                }
                if (this.currentItem.courier_tid != this.selectedItem.courier_tid) {
                    this.currentItem.courier_tid = this.selectedItem.courier_tid
                    orderSave.courier_tid = this.selectedItem.courier_tid
                    this.changeFlag = true
                    this.currentItem.courier_name = this.couriers_list.find(c => c.tid == this.currentItem.courier_tid).name
                    if (this.currentItem.courier_name == '< Не назначен >') {
                        this.currentItem.courier_name = '--'
                    }
                }

                const pay_cost = parseInt(this.selectedItem.pay_cost)
                if (!isNaN(this.selectedItem.pay_cost) && pay_cost > 0) {
                    if (this.currentItem.pay_cost != pay_cost) {
                        this.currentItem.pay_cost = pay_cost
                        orderSave.pay_cost = pay_cost
                        this.changeFlag = true
                    }
                }

                const fee_courier = parseInt(this.selectedItem.fee_courier)
                if (fee_courier || fee_courier == 0) {
                    if (this.selectedItem.date_complete) {
                        this.ordersFeeCourier += fee_courier - this.currentItem.fee_courier
                    }
                    this.currentItem.fee_courier = fee_courier
                    orderSave.fee_courier = fee_courier
                    this.changeFlag = true
                }

                const fee_point = parseInt(this.selectedItem.fee_point)
                if (fee_point || fee_point == 0) {
                    if (this.selectedItem.date_complete) {
                        this.ordersFeePoint += fee_point - this.currentItem.fee_point
                    }
                    this.currentItem.fee_point = fee_point
                    orderSave.fee_point = fee_point
                    this.changeFlag = true
                }
                this.dialogEdit = false

                if (this.changeFlag) {
                    console.log('save order', orderSave)
                    this.$store
                        .dispatch('order_set', orderSave)
                        .then(res => {
                            if (res.data.success) {
                                console.log('order saved ok')
                            } else {
                                console.log('order saved fail')
                            }
                            this.changeFlag = false
                        })
                        .catch(err => {
                            console.log('order_set error', err.name, err.message)
                        })
                }
            },
            onClickOrderEdit(item) {
                if (this.dialogStatus || this.guestNameClick || this.guestCreateClick) return
                if (item.status == config.order.status.forming) {
                    this.dialogHeader = `Заказ ${item.name} создается...`
                } else {
                    this.dialogHeader = `Заказ ${item.name}`
                }
                // this.dialogHeader = `Заказ ${item.name} /ord${item.id}`
                this.currentItem = item
                this.selectedItem = JSON.parse(JSON.stringify(item))
                this.dialogEdit = true
            },
            onClickOrderStatus(item) {
                this.dialogHeader = `Изменить статус для ${item.name}`
                this.dialogContent = `Текущий статус заказа: <span style="color: ${this.orderStatusColorHtml(item.status)};">${this.orderStatusStr(item.status)}</span>`
                this.currentItem = item
                this.dialogStatus = true
            },
            onClickOrderNew() {
                let courier = this.couriers.find(courier => courier.tid == this.currentItem.courier_tid)
                if (courier) {
                    if (courier.act_ord) {
                        courier.act_ord--
                    }
                    if (courier.act_ord == 0) {
                        courier.status = config.user.status.free
                    }
                    this.$store.dispatch('user_set', courier).then(res => {
                        if (res.data.success) {
                            console.log('user saved ok')
                        } else {
                            console.log('order saved fail')
                        }
                    })
                }

                this.currentItem.status = config.order.status.new
                this.currentItem.courier_tid = null
                this.currentItem.date_take = null
                this.currentItem.date_complete = null

                this.$store
                    .dispatch('order_set', this.currentItem)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order saved ok')
                            this.orders = this.orders.map((order, i) => {
                                if (order.id == this.currentItem.id) {
                                    order.status = this.currentItem.status
                                    order.courier_tid = this.currentItem.courier_tid
                                }
                                return order
                            })
                        } else {
                            console.log('order saved fail')
                        }
                        this.dialogStatus = false
                    })
                    .catch(err => {
                        console.log('order_set error', err.name, err.message)
                        this.dialogStatus = false
                    })
            },
            onClickOrderComplete() {
                let courier = this.couriers.find(courier => courier.tid == this.currentItem.courier_tid)
                // console.log('this.currentItem', this.currentItem)
                // console.log('courier', courier)

                if (courier) {
                    if (courier.act_ord) {
                        courier.act_ord--
                    }
                    if (courier.act_ord == 0) {
                        courier.status = config.user.status.free
                    }

                    this.$store.dispatch('user_set', courier).then(res => {
                        if (res.data.success) {
                            console.log('user saved ok')
                        } else {
                            console.log('order saved fail')
                        }
                    })
                }

                this.currentItem.status = config.order.status.complete
                this.currentItem.date_take = this.currentItem.date_ready
                this.currentItem.date_complete = this.currentItem.date_delivery

                this.$store
                    .dispatch('order_set', this.currentItem)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order saved ok')
                            this.orders = this.orders.map((order, i) => {
                                if (order.id == this.currentItem.id) {
                                    order.status = this.currentItem.status
                                    order.date_complete = this.currentItem.date_complete
                                }
                                return order
                            })
                        } else {
                            console.log('order saved fail')
                        }
                        this.dialogStatus = false
                    })
                    .catch(err => {
                        console.log('order_set error', err.name, err.message)
                        this.dialogStatus = false
                    })
            },
            onClickOrderDelete() {
                this.currentItem.status = config.order.status.deleted

                this.$store
                    .dispatch('order_delete', this.currentItem.id)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order delete ok')
                            this.orders = this.orders.filter(order => order.id != this.currentItem.id)
                        } else {
                            console.log('order delete fail')
                        }
                        this.dialogStatus = false
                    })
                    .catch(err => {
                        console.log('order_delete error', err.name, err.message)
                        this.dialogStatus = false
                    })
            },
            onClickChangeDate(arg) {
                this.d1 = this.getDate1StringFromDate(this.dates[0])
                this.d2 = this.getDate2StringFromDate(this.dates[1])
                this.updateData()
            },
            onClickDownloadData() {
                let operator_tid
                let point_id
                if (!this.userIsPoint) {
                    operator_tid = this.$store.state.user.tid
                    point_id = this.filter_point
                } else {
                    operator_tid = this.$store.state.user.operator_tid
                    point_id = this.$store.state.user.point_id
                }
                console.log('settings', this.$store.state.settings)
                let url = `${config.api.delivery}orders_export?tz=${this.$store.state.settings.tz}&tz_string=${this.$store.state.settings.tz_string}&operator_tid=${operator_tid}&date1=${this.d1}&date2=${this.d2}&point_id=${point_id}&courier=${this.filter_courier}&mode=${this.filter_order_mode}&deliveryTime=true`
                if (!this.userIsPoint) {
                    url += '&operator=1'
                }
                window.location.href = url
            },
            onClickToday() {
                this.dates[0] = moment().format('YYYY-MM-DD')
                this.dates[1] = moment().format('YYYY-MM-DD')

                this.d1 = this.getDate1String()
                this.d2 = this.getDate2String()
                this.updateData()
            },
            onClickYesterday() {
                this.dates[0] = moment().subtract(1, 'd').format('YYYY-MM-DD')
                this.dates[1] = moment().subtract(1, 'd').format('YYYY-MM-DD')

                this.d1 = this.getDate1String(-1)
                this.d2 = this.getDate2String(-1)
                this.updateData()
            },
            onClickMonth() {
                this.dates[0] = moment().subtract(30, 'd').format('YYYY-MM-DD')
                this.dates[1] = moment().format('YYYY-MM-DD')

                this.d1 = this.getDate1String(-30)
                this.d2 = this.getDate2String()
                this.updateData()
            },
            onClickUpdate() {
                this.updateData()
            },
            getGuestStr(guest) {
                let splittedStr = guest.split('Телефон:')
                return splittedStr.length > 1 ? splittedStr[0] + ' ' + splittedStr[1].substr(0, 17) : guest
            },
            getTdCatted(str) {
                return '<td>Hello</td>'
            },
            pointNameGet: function (point_id) {
                let point_name = '--'
                const point = this.points.find(point => point.id == point_id)
                if (point) {
                    point_name = point.name
                }
                return point_name
            },
            getRandomInt() {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5
            },

            updateData() {
                this.loading = true
                console.log('this.filter_courier', this.filter_courier)

                this.couriers = []

                let loading_count = 0

                this.$store
                    .dispatch('orders_date', {
                        date1: this.d1,
                        date2: this.d2,
                        point_id: this.userIsPoint ? this.$store.state.user.point_id : this.filter_point,
                        courier: this.filter_courier,
                        mode: this.filter_order_mode,
                        deliveryTime: true,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.orders = res.data.orders.reverse()
                            let fee_courier = 0
                            let fee_point = 0

                            for (let order of this.orders) {
                                if (order.date_complete) {
                                    const f_courier = parseInt( order.fee_courier)
                                    const f_point = parseInt(order.fee_point)
                                    if (f_courier) fee_courier += f_courier
                                    if (f_point) fee_point += f_point
                                }
                                if (order.status != config.order.status.new) {
                                    this.couriers.push({
                                        tid: order.courier_tid,
                                        full_name: order.courier_name,
                                    })
                                }
                            }
                            this.ordersFeeCourier = fee_courier
                            this.ordersFeePoint = fee_point

                            if (this.userIsPoint) {
                                this.couriers_list = this.couriers.map(c => ({ tid: c.tid, name: c.full_name }))
                                this.couriers_list.splice(0, 0, { tid: 'null', name: '< Не назначен >' })

                                this.filter_couriers = this.couriers.map(c => ({ tid: c.tid, name: c.full_name }))
                                this.filter_couriers.splice(0, 0, { tid: 0, name: '< Все >' })
                                this.filter_couriers.splice(1, 0, { tid: 'null', name: '< Не назначен >' })
                            }

                            console.log('orders_date', this.orders)
                            // const ordersComplete = this.orders.map(order => order.state == config.order.status.complete)
                            // const ordersNotComplete = this.orders.map(order => order.state != config.order.status.complete)
                            // this.ordersCountToday = ordersComplete.length
                            this.orderStatuses[config.order.status.forming] = 0
                            this.orderStatuses[config.order.status.new] = 0
                            this.orderStatuses[config.order.status.passed] = 0
                            this.orderStatuses[config.order.status.way_point] = 0
                            this.orderStatuses[config.order.status.way_guest] = 0
                            this.orderStatuses[config.order.status.complete] = 0

                            this.orders.forEach(order => {
                                this.orderStatuses[order.status]++
                            })

                            this.ordersComplete = this.orderStatuses[config.order.status.complete]

                            this.$store
                                .dispatch('guests_get', {
                                    date1: this.d1,
                                    date2: this.d2,
                                    point_id: this.userIsPoint ? this.$store.state.user.point_id : this.filter_point,
                                    // dateVisit: true,
                                })
                                .then(res => {
                                    if (res.data.success) {
                                        const guests = res.data.guests
                                        console.log('guests', guests)
                                        for (let order of this.orders) {
                                            if (order.guest_id) {
                                                const guest = guests.find(g => g.id == order.guest_id)
                                                if (guest) {
                                                    order.guest_name = guest.name
                                                    order.guest_phone = guest.phone
                                                }
                                            }
                                        }
                                    }
                                    loading_count++
                                })
                                .catch(err => {
                                    console.log('2 error', err.name, err.message)
                                    loading_count++
                                })
                        } else {
                            // this.ordersCountToday = 0
                            this.ordersComplete = 0
                            this.ordersFeeCourier = 0
                            this.ordersFeePoint = 0
                        }
                        loading_count++
                    })
                    .catch(err => {
                        console.log('3 error', err.name, err.message)
                        loading_count++
                    })

                this.$store
                    .dispatch('points_get')
                    .then(res => {
                        if (res.data.success) {
                            this.points = res.data.points

                            console.log('points', this.points)
                        }

                        this.filter_points = this.points.map(p => ({ id: p.id, name: p.name }))
                        this.filter_points.splice(0, 0, { id: 0, name: '< Все >' })
                        loading_count++
                    })
                    .catch(err => {
                        console.log('2 error', err.name, err.message)
                        loading_count++
                    })

                if (!this.userIsPoint) {
                    this.$store
                        .dispatch('couriers_get')
                        .then(res => {
                            if (res.data.success) {
                                this.couriers = res.data.couriers

                                console.log('couriers', this.couriers)
                            }

                            this.couriers_list = this.couriers.map(c => ({ tid: c.tid, name: c.full_name }))
                            this.couriers_list.splice(0, 0, { tid: 'null', name: '< Не назначен >' })

                            this.filter_couriers = this.couriers.map(c => ({ tid: c.tid, name: c.full_name }))
                            this.filter_couriers.splice(0, 0, { tid: 0, name: '< Все >' })
                            this.filter_couriers.splice(1, 0, { tid: 'null', name: '< Не назначен >' })
                            console.log('filter_couriers', this.filter_couriers)
                            // this.loading = false
                            loading_count++
                        })
                        .catch(err => {
                            console.log('1 error', err.name, err.message)
                            loading_count++
                        })
                } else {
                    loading_count++
                }

                // получение удаленных заказов
                this.getDeletedOrders()

                const loadingControl = () => {
                    console.log('loading_count', loading_count)
                    if (loading_count < 4) {
                        setTimeout(() => loadingControl(), 500)
                    } else {
                        this.loading = false
                    }
                }
                loadingControl()
            },
            rowItemClass(item) {
                return item.status == config.order.status.forming ? 'rowItemDisable' : ''
            },
            closeCalendar() {
                this.menu_calendar = false
            },
            clickFeeUpdate() {
                this.promptFeeUpdate = true
            },
            promptFeeUpdateYes() {
                this.promptFeeUpdate = false
                this.feeUpdate()
            },
            feeUpdate() {
                this.loading = true
                this.$store
                    .dispatch('orders_fee_calculate', {
                        date1: this.d1,
                        date2: this.d2,
                        // mode: 0,
                    })
                    .then(res => {
                        this.loading = false
                        if (res.data.success) {
                            this.updateData()
                        }
                    })
                    .catch(err => {
                        console.log('2 error', err.name, err.message)
                        this.loading = false
                    })
            },
            distance(options) {
                console.log('distance', options)
                const o = JSON.parse(options)
                const distance = parseFloat(o.distance)
                if (distance) {
                    return (distance / 1000).toFixed(1) + ' км'
                }
                return '--'
            },
        },
    }
</script>

<style lang="stylus">

    .rowItemDisable
        cursor default !important
        background #fff !important

        [role="button"]
            cursor default !important

    .orders
        height 100%
        padding-top 10px !important

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    white-space nowrap
                    padding 0 8px

            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                td
                    white-space nowrap
                    padding 0 8px
                    font-size 0.75rem
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px
                    td.tooltip-text
                        padding-left 0
                    td
                        padding-left 0

        .v-tooltip__content
            background #222
            color #fff
            border-radius 6px
            padding 5px 10px 4px
            &:hover
                cursor pointer

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px
                cursor pointer
                padding 0 6px

        .v-chip.v-size--x-small
            height 18px

        b
            font-weight 500

        .guest-error
            color #F44336

        .guest-selectable
            font-weight 400
            color #0D47A1
            cursor pointer
            &:hover
                text-decoration: underline
</style>
